import React from 'react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import './separator.css'

type SeparatorProps = {
  orientation: 'horizontal' | 'vertical'
  color: string
  height?: string
  width?: string
  decorative?: boolean
}

const Separator = (props: SeparatorProps) => {
  if (props.orientation === 'vertical') {
    return (
      <SeparatorPrimitive.Root
        style={{
          backgroundColor: props.color || 'var(--colors-mauve12)',
          margin: '0 10px',
          height: props.height,
        }}
        decorative={props.decorative || true}
        orientation={props.orientation}
      />
    )
  }

  return (
    <SeparatorPrimitive.Root
      style={{
        backgroundColor: props.color || 'var(--colors-mauve12)',
        margin: '10px 0',
        width: props.width,
      }}
      decorative={props.decorative || true}
      orientation={props.orientation}
    />
  )
}

export default Separator
