import React from 'react'
import { Copy as CopyIcon, Instagram as InstagramIcon } from 'react-feather'
import './Share.scss'

type ShareProps = {
  instagramLink: string
}

const Share = ({ instagramLink }: ShareProps) => {
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
  }

  const openNewTab = () => {
    window.open(instagramLink, '_blank')
  }

  return (
    <div className="row">
      <div className="postShareItem" onClick={copyLinkToClipboard}>
        <CopyIcon strokeWidth={1.75} />
      </div>
      <div className="postShareItem" onClick={openNewTab}>
        <InstagramIcon strokeWidth={1.75} />
      </div>
    </div>
  )
}

export default Share
