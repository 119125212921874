import React, { useEffect, useState } from 'react'
import PageTemplate from '../../templates/components/Page'
import StandardPost from '../../templates/review/Standard'
import StandardPostLoading from '../../templates/review/StandardLoading'

// used to get route subpath to fetch review post
function getNthIndex(string: string, subString: string, index: number): number {
  return string.split(subString, index).join(subString).length
}

type PostProps = {
  location: Location
}

const Post = (props: PostProps) => {
  const [restaurant, setRestaurant] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const notionPageId = props.location.pathname.substring(
      getNthIndex(props.location.pathname, '-', 4) + 1,
    )
    fetch(
      process.env.GATSBY_API_URL +
        'fetchPage?' +
        new URLSearchParams({
          pageId: notionPageId,
        }),
    )
      .then((res) => res.json())
      .then((result) => {
        // convert date string to readable format
        result.date = new Date(result.date).toLocaleDateString('en-us', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
        setRestaurant(result)
        setIsLoading(false)
      })
  }, [])

  return (
    <PageTemplate>
      {isLoading ? <StandardPostLoading /> : <StandardPost restaurant={restaurant} />}
    </PageTemplate>
  )
}

export default Post
