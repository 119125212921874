import React from 'react'
import type { Restaurant } from '../../globals'
import Skeleton from 'react-loading-skeleton'
import './Standard.scss'

const renderRestaurantTitles = () => {
  return (
    <div className="postHeader">
      <div className="postHeaderTitle">
        <Skeleton width="350px" />
        <div className="postHeaderSubtitle">
          <Skeleton width="var(--space-12)" />
        </div>
      </div>
      <div className="postHeaderTitle tierTitle">
        <Skeleton width="var(--space-7)" />
        <div className="postHeaderSubtitle">
          <Skeleton width="var(--space-12)" />
        </div>
      </div>
    </div>
  )
}

const renderRestaurantDetails = () => {
  return (
    <div className="middleContainer">
      <div className="column middleContainerColumn" style={{ width: '40%' }}>
        <div className="row middleContainerSection">
          <Skeleton width="var(--space-11)" />
        </div>
        <div className="row middleContainerSection">
          <Skeleton width="var(--space-11)" />
        </div>
        <div className="row middleContainerSection">
          <Skeleton width="var(--space-11)" />
        </div>
        <div className="row middleContainerSection">
          <Skeleton width="var(--space-11)" />
        </div>
      </div>
    </div>
  )
}

const renderRestaurantSection = () => {
  return (
    <>
      {renderRestaurantTitles()}
      {renderRestaurantDetails()}
    </>
  )
}

const renderReviewSection = () => {
  return (
    <div className="reviewContainer">
      <div className="reviewTitle">
        <Skeleton width="100%" height="var(--space-6)" />
      </div>
      <div className="row reviewHeader">
        <Skeleton width="var(--space-12)" />
      </div>
      <div className="reviewParagraph">
        <Skeleton width="100%" count={10} />
      </div>
      <div className="reviewParagraph">
        <Skeleton width="100%" count={10} />
      </div>
      <div className="reviewParagraph">
        <Skeleton width="100%" count={10} />
      </div>
      <div className="reviewParagraph">
        <Skeleton width="100%" count={10} />
      </div>
    </div>
  )
}

const StandardPostLoading = () => {
  return (
    <>
      <div className="postPictures" />
      {renderRestaurantSection()}
      {renderReviewSection()}
    </>
  )
}

export default StandardPostLoading
