import React from 'react'
import { Camera } from 'react-feather'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import PhotoAlbum from 'react-photo-album'
import './Carousel.scss'

const renderPhoto = ({ imageProps }) => {
  const { src, alt, srcSet, sizes, style, className, ...restImageProps } = imageProps
  return (
    <img
      className={className + ' carouselModalPhoto'}
      src={src}
      alt={alt}
      style={style}
      {...(srcSet ? { srcSet, sizes } : null)}
      {...restImageProps}
    />
  )
}

const Carousel = (props) => {
  const photos = props.photos.map((photo) => {
    return {
      src: photo,
      width: 1,
      height: 1,
    }
  })

  return (
    <div className="carouselContainer">
      {props.photos.map((photo, index) => (
        <div key={index} className="carouselItemContainer">
          <img src={[photo]} key={index} className="carouselItemPicture" />
        </div>
      ))}
      <DialogPrimitive.Root>
        <DialogPrimitive.Trigger asChild>
          <div className="carouselButton">
            <Camera size={18} strokeWidth={2.5} style={{ marginRight: 'var(--space-1)' }} />
            <span>Show all</span>
          </div>
        </DialogPrimitive.Trigger>
        <DialogPrimitive.Portal>
          <DialogPrimitive.Overlay className="carouselModalOverlay" />
          <DialogPrimitive.Content className="carouselModal">
            <PhotoAlbum layout="columns" spacing={5} photos={photos} renderPhoto={renderPhoto} />
          </DialogPrimitive.Content>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>
    </div>
  )
}

export default Carousel
