import React from 'react'
import { MapPin, DollarSign, BookOpen, Link2, Calendar } from 'react-feather'
import { getTierColor } from '../../utils/tiers.utils'
import { getAvatarSrc } from '../../utils/reviewers.utils'
import Link from '../../components/link'
import Carousel from './components/Carousel'
import Awards from './components/Awards'
import Separator from '../../components/separator'
import Avatar from '../../components/Avatar'
import Share from './components/Share'
import type { Restaurant } from '../../globals'
import './Standard.scss'

const renderRestaurantTitles = (restaurant: Partial<Restaurant>) => {
  return (
    <div className="postHeader">
      <div className="postHeaderTitle">
        <div>{restaurant.title}</div>
        <div className="postHeaderSubtitle">
          <MapPin strokeWidth={2.5} size={15} style={{ marginRight: 'var(--space-1)' }} />
          <Link underline external to={restaurant.mapsLink}>
            {restaurant.location}
          </Link>
        </div>
      </div>
      <div className="postHeaderTitle tierTitle" style={{ color: getTierColor(restaurant.tier) }}>
        {restaurant.tier}
        <div className="postHeaderSubtitle" style={{ marginTop: '-5px' }}>
          Connoisseurs™ Tier
        </div>
      </div>
    </div>
  )
}

const renderRestaurantDetails = (restaurant: Partial<Restaurant>) => {
  return (
    <div className="middleContainer">
      <div className="column middleContainerColumn" style={{ width: '40%' }}>
        <div className="row middleContainerSection">
          <div className="row middleContainerLabel">
            <BookOpen strokeWidth={2} size={20} style={{ marginRight: 'var(--space-1)' }} />
          </div>
          {restaurant.menu}
        </div>
        <div className="row middleContainerSection">
          <div className="row middleContainerLabel">
            <DollarSign strokeWidth={2} size={20} style={{ marginRight: 'var(--space-1)' }} />
          </div>
          {restaurant.price}
        </div>
        {restaurant.website ? (
          <div className="row middleContainerSection">
            <div className="row middleContainerLabel">
              <Link2 strokeWidth={2} size={20} style={{ marginRight: 'var(--space-1)' }} />
            </div>
            <Link
              underline
              external
              style={{ color: 'var(--colors-mauve11)' }}
              to={restaurant.website}>
              Website
            </Link>
          </div>
        ) : null}
        {restaurant.reservations ? (
          <div className="row middleContainerSection">
            <div className="row middleContainerLabel">
              <Calendar strokeWidth={2} size={20} style={{ marginRight: 'var(--space-1)' }} />
            </div>
            <Link
              underline
              external
              style={{ color: 'var(--colors-mauve11)' }}
              to={restaurant.reservations}>
              Reservations
            </Link>
          </div>
        ) : null}
      </div>
      <div className="column middleContainerColumn" style={{ width: '60%' }}>
        <div className="column middleContainerSection">
          {restaurant.content && restaurant.content.awards ? (
            <>
              <Awards awards={restaurant.content.awards} />
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const renderRestaurantSection = (restaurant: Partial<Restaurant>) => {
  return (
    <>
      {renderRestaurantTitles(restaurant)}
      {restaurant.description ? (
        <div className="postHeaderDescription">{restaurant.description}</div>
      ) : null}
      {renderRestaurantDetails(restaurant)}
    </>
  )
}

const renderReviewSection = (restaurant: Partial<Restaurant>) => {
  return (
    <div className="reviewContainer">
      <div className="reviewTitle">&quot;{restaurant.reviewTitle}&quot;</div>
      <div className="row reviewHeader">
        <div className="row">
          <Avatar small src={getAvatarSrc(restaurant.reviewer)} alt="Raymond Chen" fallback="RC" />
          <div className="reviewAuthorText">{restaurant.reviewer}</div>
          <Separator orientation="vertical" height="20px" color="var(--colors-mauve11)" />
          {restaurant.date}
        </div>
        <Share instagramLink={restaurant.instagramLink} />
      </div>
      {restaurant.content &&
        restaurant.content.review.map((paragraph, index) => (
          <div className="reviewParagraph" key={index}>
            {paragraph}
          </div>
        ))}
    </div>
  )
}

type StandardPostProps = {
  restaurant: Partial<Restaurant>
}

const StandardPost = (props: StandardPostProps) => {
  const { restaurant } = props
  return (
    <>
      <div className="postPictures">
        {restaurant.content && <Carousel photos={restaurant.content.photos} />}
      </div>
      {renderRestaurantSection(restaurant)}
      {renderReviewSection(restaurant)}
    </>
  )
}

export default StandardPost
