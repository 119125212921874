import React from 'react'
import MichelinIcon from '../../../assets/icons/Michelin.svg'
import Worlds50BestIcon from '../../../assets/icons/Worlds50Best.svg'
import JBFIcon from '../../../assets/icons/JamesBeardFoundation.svg'
import NYTIcon from '../../../assets/icons/NewYorkTimes.svg'
import ChicagoIcon from '../../../assets/icons/ChicagoTribune.svg'
import EaterIcon from '../../../assets/icons/Eater.svg'
import RelaisChateauxIcon from '../../../assets/icons/RelaisChateaux.svg'
import './Awards.scss'

const Awards = (props: any) => {
  const awardElements = props.awards.map((award: string) => {
    const awardSplit = award.split('-')

    if (awardSplit.length === 1) {
      return <div className="awardRow">{awardSplit[0]}</div>
    }

    let awardSplitAgain: string[]
    let firstLine: string = ''
    let secondLine: string = ''
    let icon: any = null

    switch (awardSplit[0]) {
      case 'Michelin':
        awardSplitAgain = awardSplit[1].split(',')
        firstLine = `${awardSplitAgain[0]} Star${parseInt(awardSplitAgain[0]) > 1 ? 's' : ''}`
        secondLine = `Michelin Guide, ${awardSplitAgain[1]}`
        icon = MichelinIcon
        break
      case 'World’s50Best':
        awardSplitAgain = awardSplit[1].split(',')
        firstLine = `#${awardSplitAgain[0]}`
        secondLine = `World's 50 Best Restaurants, ${awardSplitAgain[1]}`
        icon = Worlds50BestIcon
        break
      case 'JamesBeardFoundation':
        awardSplitAgain = awardSplit[1].split(',')
        firstLine = `${awardSplitAgain[0]}`
        secondLine = `James Beard Foundation, ${awardSplitAgain[1]}`
        icon = JBFIcon
        break
      case 'NYTimes':
        awardSplitAgain = awardSplit[1].split(',')
        firstLine = `${awardSplitAgain[0]} Star${parseInt(awardSplitAgain[0]) > 1 ? 's' : ''}`
        secondLine = `The New York Times, ${awardSplitAgain[1]}`
        icon = NYTIcon
        break
      case 'ChicagoTribune':
        awardSplitAgain = awardSplit[1].split(',')
        secondLine = `The Chicago Tribune, ${awardSplitAgain[1]}`
        firstLine = `${awardSplitAgain[0]} Star${parseInt(awardSplitAgain[0]) > 1 ? 's' : ''}`
        icon = ChicagoIcon
        break
      case 'Eater':
        awardSplitAgain = awardSplit[1].split(',')
        firstLine = `${awardSplitAgain[0]}`
        secondLine = `Eater, ${awardSplitAgain[1]}`
        icon = EaterIcon
        break
      case 'RelaisChateaux':
        awardSplitAgain = awardSplit[1].split(',')
        firstLine = `${awardSplitAgain[0]}`
        secondLine = `Relais & Châteaux, ${awardSplitAgain[1]}`
        icon = RelaisChateauxIcon
        break
    }

    return (
      <div key={award} className="awardRow">
        <div className="column awardColumn">
          <span style={{ marginBottom: '2.5px', textAlign: 'end' }}>{firstLine}</span>
          <span style={{ color: 'var(--colors-mauve11)' }}>{secondLine}</span>
        </div>
        {icon && (
          <div className="column">
            <img src={icon} className="awardIcon" />
          </div>
        )}
      </div>
    )
  })

  return <div className="column">{props.awards.length > 0 ? awardElements : null}</div>
}

export default Awards
